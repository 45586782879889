function Logo(props) {
    const blueGradient1Start = "#050606";
    const blueGradient1End = "#202249";
    const blueGradient2Start = "#4652ac";
    const blueGradient2Middle = "#24488a";
    const blueGradient2End = "#1f2148";
    const yellowGradientStart = "#f36431";
    const yellowGradientEnd = "#fbc218";

    return props.outerFill || props.innerFill ? (
        <svg viewBox="83.1 0 514 426">
            <g transform="matrix(0.1 0 0 -0.1 -200 950)">
                <path className={props.outerFill} d="M4776 7988 4133 7345 4772 6707V5418L2828 7345 4776 9318Z" />
                <path className={props.outerFill} d="M6024 7988 6667 7345 6028 6707V5418L7972 7345 6024 9318Z" />
                <path className={props.innerFill} d="M5780.3 7422h-272l102 835-590-963h272l-102-835z" />
            </g>
        </svg>
    ) : (
        <svg viewBox="83.1 0 514 426">
            <defs>
                <linearGradient id="blueGradient1Left" x1="2826" x2="4776" y1="7358" y2="7358" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={blueGradient1Start} />
                    <stop offset="1" stopColor={blueGradient1End} />
                </linearGradient>
                <linearGradient id="blueGradient1Right" x1="7972" x2="6022" y1="7358" y2="7358" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={blueGradient1Start} />
                    <stop offset="1" stopColor={blueGradient1End} />
                </linearGradient>
                <linearGradient id="blueGradient2Left" x1="2900" x2="4700" y1="7358" y2="7358" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={blueGradient2Start} />
                    <stop offset="0.7" stopColor={blueGradient2Middle} />
                    <stop offset="1" stopColor={blueGradient2End} />
                </linearGradient>
                <linearGradient id="blueGradient2Right" x1="7894" x2="6094" y1="7358" y2="7358" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={blueGradient2Start} />
                    <stop offset="0.7" stopColor={blueGradient2Middle} />
                    <stop offset="1" stopColor={blueGradient2End} />
                </linearGradient>
                <linearGradient id="yellowGradient" x1="5019.7" x2="5780.3" y1="7358" y2="7358" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={yellowGradientStart} />
                    <stop offset="1" stopColor={yellowGradientEnd} />
                </linearGradient>
            </defs>
            <g transform="matrix(0.1 0 0 -0.1 -200 950)">
                <path fill="url(#blueGradient1Left)" d="m4772 6707-1940 640 1944 641-643-643z" />
                <path fill="url(#blueGradient2Left)" d="m2831 7349 1945 1969v-1327z" />
                <path fill="url(#blueGradient2Left)" d="m4772 5418-1944 1927 1944-639z" />

                <path fill="url(#blueGradient1Right)" d="m6028 6707 1940 640-1944 641 643-643z" />
                <path fill="url(#blueGradient2Right)" d="m7971 7349-1947 1969v-1327z" />
                <path fill="url(#blueGradient2Right)" d="m6028 5418 1944 1927-1944-639z" />

                <path fill="url(#yellowGradient)" d="M5780.3 7422h-272l102 835-590-963h272l-102-835z" />
            </g>
        </svg>
    );
}

export default Logo;