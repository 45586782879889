import React from "react";

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated:</strong> 08/09/2024</p>

      <p>Welcome to Code Moda. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using the Code Moda website (“Site”) and services, you agree to these Terms of Service (“Terms”). If you do not agree with these Terms, you should not use our services.</p>

      <h2>2. Services Provided</h2>
      <p>Code Moda offers software development services for the energy sector, including but not limited to electrical engineering software, Autocad and Bricscad automation, IoT solutions, renewable energy design, cloud architecture, and web application development.</p>

      <h2>3. Use of the Site</h2>
      <p>You agree to use the Site and services only for lawful purposes and in accordance with these Terms. You are prohibited from:</p>
      <ul>
        <li>Violating any applicable laws or regulations.</li>
        <li>Impersonating or attempting to impersonate Code Moda, our employees, or other users.</li>
        <li>Engaging in any activity that could harm or disrupt the Site or services.</li>
      </ul>

      <h2>4. Intellectual Property</h2>
      <p>All content, materials, logos, designs, and trademarks displayed on the Site are the intellectual property of Code Moda, unless otherwise stated. You are not permitted to use, copy, reproduce, distribute, or modify any content without our prior written consent.</p>

      <h2>5. Third-Party Links</h2>
      <p>The Site may contain links to third-party websites or services. Code Moda is not responsible for the content or practices of these third-party websites. Accessing third-party links is at your own risk, and you are subject to their terms and conditions.</p>

      <h2>6. Limitation of Liability</h2>
      <p>Code Moda is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our Site or services. This includes, but is not limited to, damages related to loss of profits, data, or goodwill, and business interruptions.</p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>The services and content on the Site are provided “as is” without warranties of any kind, whether express or implied. Code Moda makes no warranties or representations regarding the accuracy, reliability, or availability of the Site or services.</p>

      <h2>8. Modifications to the Terms</h2>
      <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Site. It is your responsibility to review the Terms regularly. Continued use of the Site after any changes implies acceptance of the revised Terms.</p>

      <h2>9. Termination</h2>
      <p>We reserve the right to terminate or suspend your access to the Site or services at any time, without prior notice, for any reason, including violation of these Terms.</p>

      <h2>10. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of Queensland, Australia, without regard to its conflict of law principles. Any legal action or proceeding related to your use of the Site or services shall be brought exclusively in the courts of Queensland, Australia.</p>
    </div>
  );
}

export default LegalTerms;
