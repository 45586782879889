import React from "react";
import {
  ShieldCheckIcon,
  PresentationChartBarIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Logo from "./Logo";

function HeroSection(props) {
  const items = [
    {
      title: "Expertise",
      description:
        "Our expertise spans advanced electrical engineering software, IoT devices, and renewable energy solutions. We are driven by a passion for automation and precision, ensuring every project is crafted to deliver optimal performance.",
      icon: Cog6ToothIcon,
      iconColor: "text-blue-500",
    },
    {
      title: "Commitment",
      description:
        "We stand behind every solution we build. From concept to deployment, our commitment is to continuously refine and enhance our products, delivering efficiency and innovation with every update.",
      icon: ShieldCheckIcon,
      iconColor: "text-green-500",
    },
    {
      title: "Seamless",
      description:
        "Functionality drives our design philosophy. We ensure that every interface is user-friendly and efficient, so your business processes are streamlined without unnecessary complexity.",
      icon: PresentationChartBarIcon,
      iconColor: "text-fuchsia-500",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <div>
          <div className="mb-5 inline-block w-16 h-16">
            <Logo outerFill="fill-codeblue-300" innerFill="fill-yellow-200" />
          </div>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="lg:w-1/2"
          />
        </div>
        <div className="flex space-x-2">
          <div>
            <img src={props.leftImage} alt="" className="rounded-lg" width="1280" height="800" />
          </div>
          <div>
            <img src={props.rightImage} alt="" className="rounded-lg" width="600" height="800" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {items.map((item, index) => (
            <div key={index}>
              <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
                <item.icon
                  className={
                    "inline-block w-5 h-5" +
                    (item.iconColor ? ` ${item.iconColor}` : "")
                  }
                />
                <span>{item.title}</span>
              </h3>
              <p className="leading-relaxed">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
