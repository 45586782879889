import React from "react";

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-codeblue-600 max-w-none">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong>08/09/2024</p>

      <p>At Code Moda, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website.</p>

      <h2>1. Information We Collect</h2>
      <p>We use <strong>Simple Analytics</strong>, a privacy-friendly analytics tool that does not track personal information or use cookies. Simple Analytics only collects anonymized, aggregate data to help us understand how our website is being used. This information includes:</p>
      <ul>
        <li><strong>Page Views:</strong> Which pages are visited and for how long.</li>
        <li><strong>Referrer Information:</strong> How you arrived at our website (e.g., from a search engine or external link).</li>
        <li><strong>Device Information:</strong> General data such as the device type (desktop, mobile) and the browser used.</li>
      </ul>
      <p>No personally identifiable information (PII) such as your name, email address, or IP address is collected.</p>

      <h2>2. How We Use Your Information</h2>
      <p>The anonymized data we collect is used to:</p>
      <ul>
        <li>Monitor and analyze website performance.</li>
        <li>Improve the content and usability of our website.</li>
        <li>Make informed decisions about website enhancements.</li>
      </ul>
      <p>We do not sell, trade, or share your information with third parties for marketing purposes.</p>

      <h2>3. Cookies</h2>
      <p>Our website does not use cookies to track or identify users. The analytics we use are entirely anonymous and do not require cookies for data collection.</p>

      <h2>4. Third-Party Services and Hosting</h2>
      <p>Our website is hosted on Amazon Web Services (AWS) using CloudFront for content delivery and security purposes. AWS may collect certain information such as IP addresses, user agents, and request data for performance monitoring, security, and optimization. However, Code Moda does not use this information to personally identify users.</p>
      <p>For more information about how AWS handles data, please review <a href="https://aws.amazon.com/privacy/" target="_blank">AWS's Privacy Notice</a>.</p>

      <h2>5. Third-Party Links</h2>
      <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to read the privacy policies of any third-party sites you visit.</p>

      <h2>6. Data Security</h2>
      <p>We take the security of your data seriously. While we do not collect personal information, we implement appropriate security measures to protect any information processed through the website.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page with an updated "Last Updated" date. We encourage you to review this page periodically to stay informed about how we are protecting your privacy.</p>

      <h2>8. Your Privacy Rights</h2>
      <p>Since we do not collect personal information, there are no specific privacy rights such as requests for data access, deletion, or rectification. If you have any concerns or questions, feel free to reach out to us.</p>
    </div>
  );
}

export default LegalPrivacy;
