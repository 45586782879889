import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import HeroSection2 from "./../components/HeroSection2";
import { Helmet } from "react-helmet";

function IndexPage(props) {
  return (
    <>
      <Helmet>
        <link rel="preload" href="images/engineering.webp" as="image" />
        <link rel="preload" href="images/dataoffice.webp" as="image" />
      </Helmet>
      <Meta />
      <HeroSection
        title={
          <>
            We are <span className="font-light">Code Moda</span>
          </>
        }
        subtitle="Innovative solutions for the energy sector, designed to transform your business with cutting-edge software."
        strapline=""
        size="md"
        bgColor="bg-codeblue-800"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
        leftImage="images/engineering.webp"
        rightImage="images/dataoffice.webp"
      />
      <FeaturesSection
        title="Empowering Your Business"
        subtitle="Unlock the potential of your operations with our custom software solutions for electrical engineering, renewable energy, and cloud architecture."
        strapline="It's time to automate"
        size="md"
        bgColor="bg-codeblue-100"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <HeroSection2
        title="Powering Your Energy and Engineering Solutions"
        subtitle="Focus on building a sustainable future. Code Moda provides the software expertise you need to automate, innovate, and grow your business."
        strapline=""
        size="md"
        bgColor="bg-codeblue-800"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
    </>
  );
}

export default IndexPage;
