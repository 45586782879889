import React from "react";
import Section from "./Section";
import { Link } from "./../util/router";

function Footer(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="flex flex-col md:flex-row-reverse md:justify-between space-y-6 md:space-y-0 text-center md:text-left text-sm lg:text-base">
          <nav className="space-x-4">
            {[
              {
                url: "https://www.linkedin.com/company/code-moda",
                label: "LinkedIn",
                icon: (
                  <path d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
                ),
              },
            ].map((link, index) => (
              <a
                href={link.url}
                aria-label={link.label}
                className="text-gray-400 hover:text-codeblue-600"
              >
                <svg
                  className="inline-block w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  {link.icon}
                </svg>
              </a>
            ))}
          </nav>
          <nav className="flex flex-wrap gap-y-2 space-x-5 sm:space-x-7">
            {[
              { url: "/", name: "About" },
              { url: "/faq", name: "FAQs" },
              { url: "/contact", name: "Contact" },
              { url: "/legal/terms-of-service", name: "Terms of Service" },
              { url: "/legal/privacy-policy", name: "Privacy Policy" },
            ].map((link, index) => (
              <Link
                to={link.url}
                className="font-medium text-gray-600 hover:text-gray-500"
                key={index}
              >
                {link.name}
              </Link>
            ))}
          </nav>
          <div className="text-gray-600">
            <span className="font-medium">Code Moda Pty Ltd</span> ©
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
