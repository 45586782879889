import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        title="Contact Us"
        subtitle="Feel free to contact us and we will get back to you as soon as possible."
        strapline=""
        size="md"
        bgColor="bg-codeblue-800"
        bgImage="/images/brisbane.webp"
        bgImageOpacity={0.17}
        textColor="text-white"
      />
    </>
  );
}

export default ContactPage;
