import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqItemsCol1 = [
    {
      question: "What services does Code Moda provide?",
      answer:
        "We specialize in software development for the energy sector, including electrical engineering software, advanced Autocad/Bricscad automation, IoT solutions, renewable energy design, and cloud architecture.",
    },
    {
      question: "What industries do you serve?",
      answer:
        "We focus on the energy sector but also work with industries requiring automation, cloud solutions, and custom software development, such as renewable energy and electrical engineering.",
    },
    {
      question: "Can you develop custom software solutions for my business?",
      answer:
        "Yes, we create tailored solutions based on your unique needs, whether it’s automating processes or developing specialized energy monitoring platforms.",
    },
  ];
  
  const faqItemsCol2 = [
    {
      question: "Do you provide cloud solutions?",
      answer:
        "Yes, we design and implement cloud-based solutions, including AWS, Azure, serverless architecture, databases, APIs, and lambda functions.",
    },
    {
      question: "What makes Code Moda different from other software development companies?",
      answer:
        "We specialize in the energy sector and automation, combining deep technical expertise with a focus on efficiency, scalability, and user-friendly designs.",
    },
    {
      question: "Can you help with integrating renewable energy systems?",
      answer:
        "Yes, we offer expertise in renewable energy design and software solutions, including system monitoring, data analytics, and automation.",
    }
  ];
  
  
  const faqItemsCol3 = [
    {
      question: "Can you help with integrating renewable energy systems?",
      answer:
        "Yes, we offer expertise in renewable energy design and software solutions, including system monitoring, data analytics, and automation.",
    },
    {
      question: "Do you offer ongoing support after project completion?",
      answer:
        "Yes, we provide post-project support, including updates and improvements, ensuring that your software continues to meet your evolving business needs.",
    },
    {
      question: "How can I get in touch with Code Moda?",
      answer:
        "You can contact us through the form on our Contact page. We look forward to discussing your project.",
    }
  ];  

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-4">
            {faqItemsCol1.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h2 className="font-semibold mb-2">{item.question}</h2>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
          <div className="space-y-4">
            {faqItemsCol2.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h2 className="font-semibold mb-2">{item.question}</h2>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
          <div className="space-y-4">
            {faqItemsCol3.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h2 className="font-semibold mb-2">{item.question}</h2>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
        </div>

        {props.showSupportButton && (
          <div className="text-center">
            <Button
              href={props.supportUrl}
              target="_blank"
              size="lg"
              variant="simple"
              startIcon={
                <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />
              }
            >
              Go to support center
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default FaqSection;
